import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ServiceCard from "@components/services/service-card";
import { SERVICE_DATA } from "@data";
const ServicesHome = () => {
  const { sectionTitle, posts } = SERVICE_DATA;
  return (
    <section className="service-one">
      <Container>
        {/*<div className="block-title text-center">*/}
        {/*  <p>{sectionTitle.text}</p>*/}
        {/*  <h3>{sectionTitle.title}</h3>*/}
        {/*</div>*/}
        <div style={{"height": "110px"}}></div>
        <Row style={{"marginLeft": "-120px", "marginRight": "-120px"}}>
            <Col xl={1} lg={1}></Col>
          {posts.map(({ extraClassName, icon, title, text }, index) => (
            <Col xl={2} lg={2} md={6} key={`service-card-key-${index}`}>
              <ServiceCard
                extraClassName={extraClassName}
                icon={icon}
                title={title}
                text={text}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default ServicesHome;
