import React from "react";
import { Link } from "gatsby";
import { Link as ScrollLink } from "react-scroll";
const NavLinks = () => {
  const handleDropdownStatus = (e) => {
    let clickedItem = e.currentTarget.parentNode;
    clickedItem.querySelector(".dropdown-list").classList.toggle("show");
  };
  return (
    <ul className="main-nav__navigation-box one-page-scroll-menu">
      <li>
        <Link to="/">
          首页
        </Link>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="first__flag"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          业务介绍
        </ScrollLink>
      </li>
      <li>
        <ScrollLink
          activeClass="current"
          to="second__flag"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
        >
          关于我们
        </ScrollLink>
      </li>
      {/*<li>*/}
      {/*  <ScrollLink*/}
      {/*    activeClass="current"*/}
      {/*    to="testimonials"*/}
      {/*    spy={true}*/}
      {/*    smooth={true}*/}
      {/*    offset={-70}*/}
      {/*    duration={500}*/}
      {/*  >*/}
      {/*    Testimonials*/}
      {/*  </ScrollLink>*/}
      {/*</li>*/}
      {/*<li>*/}
      {/*  <ScrollLink*/}
      {/*    activeClass="current"*/}
      {/*    to="screens"*/}
      {/*    spy={true}*/}
      {/*    smooth={true}*/}
      {/*    offset={-70}*/}
      {/*    duration={500}*/}
      {/*  >*/}
      {/*    Screens*/}
      {/*  </ScrollLink>*/}
      {/*</li>*/}
      {/*<li className="dropdown">*/}
      {/*  <ScrollLink*/}
      {/*    activeClass="current"*/}
      {/*    to="blog"*/}
      {/*    spy={true}*/}
      {/*    smooth={true}*/}
      {/*    offset={-70}*/}
      {/*    duration={500}*/}
      {/*  >*/}
      {/*    News*/}
      {/*  </ScrollLink>*/}
      {/*  <button className="dropdown-btn" onClick={handleDropdownStatus}>*/}
      {/*    <i className="fa fa-angle-right"></i>*/}
      {/*  </button>*/}
      {/*  <ul className="dropdown-list">*/}
      {/*    <li>*/}
      {/*      <Link to="/blog">*/}
      {/*        News*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*    <li>*/}
      {/*      <Link to="/blog-details">*/}
      {/*        News Details*/}
      {/*      </Link>*/}
      {/*    </li>*/}
      {/*  </ul>*/}
      {/*</li>*/}
    </ul>
  );
};

export default NavLinks;
