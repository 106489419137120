import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import bgImage from "@images/shapes/pricing-1-shape-1.png";
import { PRICING_DATA } from "@data";
import PriceCard from "./price-card";

const PriceHome = () => {
  const { sectionTitle, paragraph } = PRICING_DATA;

  return (
    <section id="second__flag" className="pricing-one">
      {/*<div*/}
      {/*  className="pricing-one-shape-1"*/}
      {/*  style={{ backgroundImage: `url(${bgImage})` }}*/}
      {/*></div>*/}
      <Container>
        <div className="block-title text-center">
          <p>{sectionTitle.text}</p>
          <h3>{sectionTitle.title}</h3>
        </div>

        <div>
          <p className="cta-one__text ">{paragraph}</p>
        </div>
        <div style={{height: "100px"}}></div>

        <div className="tabed-content">

        </div>
      </Container>
    </section>
  );
};

export default PriceHome;
