import React, {useEffect, useRef, useState} from "react";
import {Button, Card, Form, Container, Row, Col, Modal, Alert} from "react-bootstrap";
import ImageIcon1 from "@images/report-icon/blacklist_detection_01.png";
import ImageIcon2 from "@images/report-icon/blacklist_detection_02.png";
import ImageIcon3 from "@images/report-icon/blacklist_detection_03.png";
import ImageIcon4 from "@images/report-icon/blacklist_detection_04.png";
import ImageIcon5 from "@images/report-icon/blacklist_detection_05.png";
import Validate from "../libs/validate";
import axios from "axios";

const IconsReportList = [
    ImageIcon1,
    ImageIcon2,
    ImageIcon3,
    ImageIcon4,
    ImageIcon5
]


const ItemBlock = (props) => {
    const {data, iconPath} = props
    return <div className="banner-form-report__item">
        <h4><img alt="" src={iconPath || IconsReportList[0]}/> {data.title}</h4>
        <ul className="pt-2 pb-2">
            {
                data.children.map((item, idx) => {
                    return item.errState === -1 ? <li className="error" key={idx}><span>异常</span>{item.title}</li> :
                        <li key={idx}><span>无异常</span>{item.title}</li>
                })
            }
            {/*<li><span>无异常</span>P2P讨债名单</li>*/}
            {/*<li className="error"><span>异常</span>P2P讨债名单</li>*/}
        </ul>
    </div>
}

const SearchBox = ({title, crumbTitle}) => {
    const [showResult, setShowResult] = useState(false);
    const [reportList, setReportList] = useState([]);
    const [reportErrorFlag, setReportErrorFlag] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [formData, setFormData] = useState([
        {
            name: "姓名",
            key: "username",
            type: "text",
            value: "",
            validFunc: Validate.isCnName,
            errMsg: "",
            required: true
        },
        {
            name: "身份证号",
            key: "idCard",
            type: "text",
            value: "",
            validFunc: Validate.validateIdCard,
            errMsg: "",
            required: true
        },
        {
            name: "手机号",
            key: "phone",
            type: "text",
            value: "",
            validFunc: Validate.isMobile,
            errMsg: "",
            required: true
        }
    ]);
    // const [reportData, setReportData] = useState([]);
    const [captchaImg, setCaptchaImg] = useState("");
    const [captchaVal, setCaptchaVal] = useState(""); // 验证码
    const captchaCode = useRef("");

    const handleClose = () => setShowResult(false);
    const handleShow = () => setShowResult(true);


    useEffect(() => {
        fetchCaptcha()
        // setReportList(resData.filter(item => item.privilegeCode.startsWith('blacklist_')))
    }, [])

    const fetchCaptcha = () => {
        axios.get('/api/captchaImage')
            .then(response => {
                console.log(response.data);
                setCaptchaImg(response.data.data.image);
                captchaCode.current = response.data.data.uuid;
            })
            .catch(error => {
                console.error('There was an error fetching the data!', error);
            });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const params = {}
        let isValid = true
        formData.forEach(item => {
            params[item.key] = item.value
            if (!item.validFunc(item.value)) {
                item.errMsg = `请输入正确的${item.name}`
                isValid = false
            } else {
                item.errMsg = ""
            }
        })
        setFormData([...formData])

        if (isValid) {
            setLoading(true);
            axios.post('/api/credit/authInfo', {
                ...params,
                uuid: captchaCode.current,
                code: captchaVal
            })
                .then(response => {
                    fetchCaptcha();
                    if (response.data.status !== 0) {
                        alert(response.data.message)
                        return;
                    }
                    console.log(response.data);
                    setShowResult(true);
                    setReportList(getReportList(response.data.data))
                })
                .catch(error => {
                    console.error('There was an error fetching the data!', error);
                }).finally(() => {
                setLoading(false);
            });
        }
    };

    const getReportList = (data) => {
        // errState: -1 异常 1正常

        const result = [
            {
                title: '黑名单检测',
                children: [
                    {
                        title: 'P2P逃废债名单检测',
                        errState: data?.baihang_special_list?.p2pEscapeDebtStatus == 1 ? -1 : 1
                    },
                    {
                        title: '高法失信被执行人名单',
                        errState: data?.baihang_special_list.supremeCourtExecutedStatus == 1 ? -1 : 1
                    }
                ]
            },
            {
                title: '逾期记录检测',
                children: [
                    {
                        title: '银行机构逾期记录检测',
                        errState: data?.ppbr_145_sl_id_bank_lost_allnum > 0 ? -1 : 1
                    },
                    {
                        title: '非银行机构逾期记录检测',
                        errState: data?.ppbr_120_sl_id_nbank_lost_allnum > 0 ? -1 : 1
                    },
                    {
                        title: '严重逾期检测',
                        errState: [-1, 1].includes(+data?.baihang_special_list?.maxOverdueStatus) ? -1 : 1
                    }
                ]
            },
            {
                title: '借贷风险检测',
                children: [
                    {
                        title: '近6个月借贷申请次数检测',
                        errState: [-1, 1].includes(+data?.baihang_special_list?.multiQuery8_180D) ? -1 : 1
                    },
                    {
                        title: '近3个月借贷申请次数检测',
                        errState: [-1, 1].includes(+data?.baihang_special_list?.multiQuery6_90D) ? -1 : 1
                    },
                    {
                        title: '近1个月借贷申请次数检测',
                        errState: [-1, 1].includes(+data?.baihang_special_list?.multiQuery5_30D) ? -1 : 1
                    },
                    {
                        title: '近期借贷平台活跃度检测',
                        errState: [-1, 1].includes(+data?.baihang_special_list?.multiQuery3_3D) ? -1 : 1
                    }
                ]
            },
            {
                title: '拒贷雷达',
                children: [
                    {
                        title: '近6月非银机构借款笔数检测',
                        errState: [-1, 1].includes(+data?.baihang_special_list?.multiQuasiFin3_180D) ? -1 : 1
                    },
                    {
                        title: '近6月助贷机构借款笔数检测',
                        errState: [-1, 1].includes(+data?.baihang_special_list?.multiSubsidy2_180D) ? -1 : 1
                    },
                    {
                        title: '近3月借款笔数检测',
                        errState: [-1, 1].includes(+data?.baihang_special_list?.multiPartyLoan3_90D) ? -1 : 1
                    },
                    {
                        title: '借贷违约风险监测',
                        errState: [-1, 1].includes(+data?.baihang_special_list?.multiPartyLoanStatus) ? -1 : 1
                    }
                ]
            },
            {
                title: '反欺诈等级检测',
                children: [
                    {
                        title: '欺诈风险防范检测',
                        errState: data?.baihang_anti_fraud_score?.sensum_fraudScore > 700 ? -1 : 1

                    },
                    {
                        title: '反欺诈评分检测',
                        errState: data?.baihang_anti_fraud_score?.sensum_fraudScore > 700 ? -1 : 1

                    },
                    {
                        title: '反欺诈等级检测',
                        errState: data?.baihang_anti_fraud_score?.sensum_fraudScore > 700 ? -1 : 1
                    }
                ]
            },
            {
                title: '信用分查询',
                children: [
                    {
                        title: '信用分查询',
                        errState: 1
                    }
                ]
            }
        ]
        // setReportErrorFlag
        result.forEach((item, index) => {
            item.children.forEach((child, index) => {
                if (child.errState === -1) {
                    setReportErrorFlag(true); // 标记报告为异常
                }
            })
        })
        return result
    }

    return <>
        <Modal show={showResult} onHide={handleClose} size="lg" backdrop="static" animation={true} keyboard={false}>
            <Modal.Header closeButton>
                <Modal.Title>查询结果</Modal.Title>
            </Modal.Header>
            <Modal.Body className="banner-form-report-box">
                {
                    reportErrorFlag ? <Alert variant="danger">
                        {formData[0].value}，您的信用风险监测发现异常！
                    </Alert> : <Alert variant="success">
                        {formData[0].value}，您的信用风险监测未发现异常
                    </Alert>
                }
                {
                    reportList.map((item, index) => <Card border={"light"} key={index} className="shadow-sm mb-3">
                        <Card.Body>
                            <ItemBlock iconPath={IconsReportList[index]} data={item}></ItemBlock>
                        </Card.Body>
                    </Card>)
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={handleClose}>
                    关闭
                </Button>
            </Modal.Footer>
        </Modal>

        <Card border="light" className="banner-form shadow-sm rounded">
            {/*<Card.Img variant="top" src="holder.js/100px180" />*/}
            <Card.Body>
                <Card.Title><span className="text-dark ">报告查询</span></Card.Title>
                <Form onSubmit={handleSubmit}>

                    {
                        formData.map((item, index) => <Form.Group key={index} controlId={item.key}>
                            <Form.Label>{item.name}</Form.Label>
                            <Form.Control isInvalid={!!item.errMsg}
                                          required
                                          value={item.value}
                                          onChange={e => setFormData(formData.map((item, i) => i === index ? {
                                              ...item,
                                              value: e.target.value.trim()
                                          } : item))}
                                          type={item.type}
                                          placeholder={`请输入${item.name}`}/* size="sm" *//>
                            <Form.Control.Feedback type="invalid">
                                {item.errMsg}
                            </Form.Control.Feedback>
                        </Form.Group>)
                    }

                    <Row noGutters>
                        <Col md={8}>
                            <Form.Group controlId="formGroupPassword">
                                <Form.Label>验证码</Form.Label>
                                <Form.Control maxLength={6} required type="text" placeholder="请输入右侧图形验证码"
                                              onChange={e => setCaptchaVal(e.target.value.trim())}/>
                            </Form.Group>
                        </Col>
                        <Col md={4} className="clearfix">
                            <img className="banner-form__capture float-right" onClick={fetchCaptcha}
                                // src={`https://demo.ruoyi.vip/captcha/captchaImage?type=math`} alt=""/>
                                 src={`data:image/png;base64,${captchaImg}`} alt=""/>

                        </Col>
                    </Row>


                    {/*<Form.Group controlId="name">*/}
                    {/*    /!*<Form.Label>身份证号</Form.Label>*!/*/}
                    {/*    <Form.Control isInvalid={'222'} required type="text" placeholder="请输入您的姓名" />*/}
                    {/*    <Form.Control.Feedback type="invalid">*/}
                    {/*        'error'*/}
                    {/*    </Form.Control.Feedback>*/}
                    {/*</Form.Group>*/}
                    {/*<Form.Group controlId="phone">*/}
                    {/*    /!*<Form.Label>身份证号</Form.Label>*!/*/}
                    {/*    <Form.Control required type="text" placeholder="请输入您的手机号" />*/}
                    {/*</Form.Group>*/}
                    {/*<Form.Group controlId="idCard">*/}
                    {/*    /!*<Form.Label>身份证号</Form.Label>*!/*/}
                    {/*    <Form.Control required type="text" placeholder="请输入您的身份证号" />*/}
                    {/*</Form.Group>*/}


                    {/*<Row noGutters>*/}
                    {/*    <Col md={8}>*/}
                    {/*        <Form.Group controlId="formGroupPassword">*/}
                    {/*            /!*<Form.Label>Password</Form.Label>*!/*/}
                    {/*            <Form.Control type="text" placeholder="请输入右侧图形验证码"/>*/}
                    {/*        </Form.Group>*/}
                    {/*    </Col>*/}
                    {/*    <Col md={4} className="clearfix">*/}
                    {/*        <img className="banner-form__capture float-right"*/}
                    {/*             src="https://demo.ruoyi.vip/captcha/captchaImage?type=math" alt=""/>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Form.Group as={Row} controlId="isAgree">
                        <Col>
                            <Form.Check feedbackTooltip required
                                        label={<span className="protocol-text">我同意 <a href="/service_agreement.html"
                                                                                         target="_blank">《个人信用报告查询服务协议》</a>、<a
                                            href="/privacy.html" target="_blank">《隐私协议》</a>和<a
                                            href="/declaration.html" target="_blank">《平台声明》</a></span>}/>
                        </Col>
                    </Form.Group>
                    <Button disabled={isLoading} variant="primary" type="submit" size="normal"
                            block={true}>立即查询</Button>
                </Form>
            </Card.Body>
        </Card>
    </>
};

export default SearchBox;
