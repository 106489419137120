export default class Validate {
    static VALIDATE_PHONE = 0 // 手机号
    static VALIDATE_CARDID = 1 // 身份证号
    static VALIDATE_CNV2 = 2 // cnv2码
    static EMAIL = 3 // 邮箱
    static PERSONALEMAIL = 4 // 邮箱
    static VALIDATE_PASSWORD = 5 // 密码
    static VALIDATE_CODE = 6 // 验证码
    static VALIDATE_NUM = 7 // 数字
    static VALIDATE_FLOAT = 8 // 小数点
    static VALIDATE_DATE = 10 // 日期
    static VALIDATE_TEL = 11 // 固话
    /**
     * 是否合法的密码组合，判断条件（字母开头、字母数字组合、长度6~20字符）
     * @return {boolean}
     */
    static isPassword(val) {
        if (/^\d+$/.test(val) && !/^[A-Za-z]+$/.test(val)) {
            return false
        }
        if (/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,20}$/.test(val)) {
            return true
        }
        return false
    }

    /**
     * 是否合法的URL
     * @return {boolean}
     */
    static isUrl(val) {
        const reg = /http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w- ./?%&=]*)?/
        return reg.test(val)
    }

    /**
     * 是否合法的手机号码
     * @return {boolean}
     */
    static isMobile(val = '') {
        const reg = /^1[3456789]\d{9}$/
        return Validate.isMobileXuni(val) && reg.test(val)
    }

    /**
     * 是否是以虚拟号段开头
     * @return {boolean}
     */
    static isMobileXuni(val = '') {
        if (
            val.startsWith('165') ||
            val.startsWith('167') ||
            val.startsWith('170') ||
            val.startsWith('171') ||
            val.startsWith('162')
        ) {
            return false
        }
        return true
    }
    /**
     * 是否合法的固话号码
     * @return {boolean}
     */
    static isTel(val = '') {
        // let reg = /^(0[0-9]{2,3}\-)([2-9][0-9]{6,7})+(\-[0-9]{1,4})?$/
        const reg = /^0(10|2[0-5789]|\d{3})-?\d{7,8}$/
        return reg.test(val)
    }

    /**
     * 是否中英文字符，只匹配中文英文，长度1至30位
     * @return {boolean}
     */
    static isCnEnName(val) {
        const reg = /^[\u4e00-\u9fa5a-zA-Z·]{1,30}$/
        return reg.test(val)
    }

    /**
     * 验证姓名，只匹配中文，长度1至10位
     * @return {boolean}
     */
    static isCnName(val) {
        const reg = /^[\u4e00-\u9fa5]{1,12}$/
        return reg.test(val)
    }

    /**
     * 校验身份证号码
     * @export
     * @param {string} idCardNo
     * @returns
     */
    static validateIdCard(idcard) {
        if (typeof idcard !== 'string') {
            return false
        }
        idcard = idcard.toUpperCase()
        var length = idcard.length
        var AI, year, month, day
        // 判断身份证是否是15位或18位
        if (length != 15 && length != 18) {
            // 身份证长度不对
            return false
        }
        // 18位身份证前17位数字，如果是15位的身份证所有号码都是数字
        if (length == 18) {
            AI = idcard.substring(0, 17)
        } else if (length == 15) {
            AI = idcard.substring(0, 6) + '19' + idcard.substring(6, 15)
        }
        if (!Validate.validate(AI, Validate.VALIDATE_NUM)) {
            // 不为纯数字
            return false
        }
        year = AI.substring(6, 10) // 年份
        month = AI.substring(10, 12) // 月份
        day = AI.substring(12, 14) // 天
        if (!Validate.validate(year + '-' + month + '-' + day, Validate.VALIDATE_DATE)) {
            return false
        }
        var cityCode = {
            11: 'bj',
            12: 'tj',
            13: 'hb',
            14: 'sx',
            15: 'nm',
            21: 'ln',
            22: 'jl',
            23: 'hlj',
            31: 'sh',
            32: 'js',
            33: 'zj',
            34: 'ah',
            35: 'fj',
            36: 'jx',
            37: 'sd',
            41: 'henan',
            42: 'hubei',
            43: 'hunan',
            44: 'gd',
            45: 'gx',
            46: 'hainan',
            50: 'cq',
            51: 'sc',
            52: 'gz',
            53: 'yn',
            54: 'xz',
            61: 'ssx',
            62: 'gs',
            63: 'qh',
            64: 'nx',
            65: 'xj',
            71: 'tw',
            81: 'xg',
            82: 'am',
            91: 'gw',
        }
        if (2016 - year > 150 || 2016 - year < 0) {
            return false
        }
        if (20161012 - (year + '' + month + day) < 0) {
            return false
        }
        if (!cityCode[AI.substring(0, 2)]) {
            return false
        }
        if (length == 18 && !Validate.isVarifyCode(AI, idcard)) {
            return false
        }
        return true
    }

    static validate(str, type) {
        var reg
        switch (type) {
            case Validate.VALIDATE_PHONE:
                reg = /^(1[3|5|6|4|8|7|9][0-9])\d{8}$/
                break
            case Validate.VALIDATE_CARDID:
                reg = /^\d{17}(\d|X|x)$/
                break
            case Validate.VALIDATE_CNV2:
                reg = /(^\d{3}$)/
                break
            case Validate.VALIDATE_CODE:
                reg = /(^\d{4}$)/
                break
            case Validate.EMAIL:
                var reg =
                    /^([a-zA-Z0-9]+[_|_|.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[-|_|_|.]?)*[a-zA-Z0-9]+\.(?:com|cn|net|org|hk)$/
                break
            case Validate.PERSONALEMAIL:
                var reg =
                    /^([a-zA-Z0-9_-])+@(qq.com|163.com|sogou.com|vip.qq.com|sina.com|sohu.com|189.cn|126.com|139.com)+/
                break
            case Validate.VALIDATE_PASSWORD:
                reg = /^\d{6}$/
                break
            case Validate.VALIDATE_NUM:
                reg = /^\d*$/
                break
            case Validate.VALIDATE_FLOAT:
                reg = /^[0-9]+([.]\d{1,2})?$/
                break
            case Validate.VALIDATE_LIMIT_PASSWORD:
                reg = /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{8,16}$/
                break
            case Validate.VALIDATE_DATE:
                reg =
                    /^((((19|20)\d{2})-(0?[13-9]|1[012])-(0?[1-9]|[12]\d|30))|(((19|20)\d{2})-(0?[13578]|1[02])-31)|(((19|20)\d{2})-0?2-(0?[1-9]|1\d|2[0-8]))|((((19|20)([13579][26]|[2468][048]|0[48]))|(2000))-0?2-29))$/
                break
            case Validate.VALIDATE_TEL:
                reg = /^0(10|2[0-5789]|\d{3})-?\d{7,8}$/
                break
        }
        if (type == Validate.PERSONALEMAIL || type == Validate.EMAIL) {
            str = str.toLowerCase()
        }
        return reg.test(str)
    }
    /*
     * 判断第18位校验码是否正确
     * 第18位校验码的计算方式：
      1. 对前17位数字本体码加权求和
      公式为：S = Sum(Ai * Wi), i = 0, ... , 16
      其中Ai表示第i个位置上的身份证号码数字值，Wi表示第i位置上的加权因子，其各位对应的值依次为： 7 9 10 5 8 4 2 1 6 3 7 9 10 5 8 4 2
      2. 用11对计算结果取模
      Y = mod(S, 11)
      3. 根据模的值得到对应的校验码
      对应关系为：
      Y值：     0  1  2  3  4  5  6  7  8  9  10
      校验码： 1  0  X  9  8  7  6  5  4  3   2
    */
    static isVarifyCode(AI, idstr) {
        var varifyCode = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2']
        var wi = [
            '7',
            '9',
            '10',
            '5',
            '8',
            '4',
            '2',
            '1',
            '6',
            '3',
            '7',
            '9',
            '10',
            '5',
            '8',
            '4',
            '2',
        ]
        var sum = 0
        for (var i = 0; i < 17; i++) {
            sum += AI[i] * wi[i]
        }
        var modValue = sum % 11
        AI = AI + '' + varifyCode[modValue]
        if (!(AI == idstr)) {
            return false
        }
        return true
    }
    /**
     * 验证银行卡号
     */
    static validateBankAccount(bankno) {
        return [16, 17, 18, 19].includes(bankno.length)
    }

    /**
     * 校验车牌号合规
     * @return {boolean}
     */
    static checkLicensePlate(licensePlate) {
        return /^(?=.*[A-HJ-NP-Z0-9])(?!^[A-Za-z]+$)[A-HJ-NP-Z]{1}[A-HJ-NP-Z0-9]{5,6}$/.test(
            licensePlate
        )
    }
}
